import { BsArrowUpRightSquareFill } from "react-icons/bs";

export default function Landing_Contact() {
  return (
    <div className="contactBg">
      <div className="lg:px-4 xl:px-8 px-72 py-24">
        <div className="md:text-[24px] xl:text-[34px] text-[44px] text-[#000000] font-readex_regular text-center">
          Contact{" "}
          <span className="text-[#3E46FE] font-readex_semibold">Us</span>
        </div>

        <div className="md:text-[15px] text-[17px] text-[#8D8D8D] font-readex_regular text-center mt-2">
          we love to hear from you guys! whether it is feedback for the app,
          ideas, anything!
        </div>

        <div className="flex md:flex-col items-center gap-7 mt-20">
          <div className="w-full">
            <div className="text-[16px] text-[#333333] font-readex_regular">
              Your Name
            </div>
            <input
              type="text"
              placeholder="Enter your name"
              className="bg-[#F5F6FF] text-[16px] outline-none font-readex_regular w-full px-6 py-4 mt-2 rounded-2xl"
            />
          </div>

          <div className="w-full">
            <div className="text-[16px] text-[#333333] font-readex_regular">
              Email Address
            </div>
            <input
              type="text"
              placeholder="Enter your email"
              className="bg-[#F5F6FF] text-[16px] outline-none font-readex_regular w-full px-6 py-4 mt-2 rounded-2xl"
            />
          </div>
        </div>

        <div className="w-full mt-6">
          <div className="text-[16px] text-[#333333] font-readex_regular">
            Message
          </div>
          <textarea
            rows={5}
            type="text"
            placeholder="Send Message"
            className="bg-[#F5F6FF] text-[16px] outline-none font-readex_regular w-full px-6 py-4 mt-2 rounded-2xl"
          />
        </div>

        <a className="flex items-center justify-center w-full cursor-pointer">
          <div
            // href="mailto:support@allignx.com"
            target="_blank"
            className="flex items-center bg-gradient-to-r from-[#A0A6FF] to-[#5A65FF] w-fit px-1 rounded-lg mt-11"
          >
            <div className="font-readex_medium text-[16px] text-[#fff] py-3.5 px-6">
              Send Message
            </div>
            <BsArrowUpRightSquareFill className="text-[#fff] text-[40px]" />
          </div>
        </a>
      </div>
    </div>
  );
}
