import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingPage from "./pages/LandingPage";
import Privacy from "./pages/Privacy-policy";
import { Terms } from "./pages/Terms-of-condition";
import Analysis from "./pages/Analysis";
import Policys from "./pages/Policys";
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<Terms />} /> */}
        <Route path="/service-provider" element={<Analysis />} />
        <Route path="/terms-policies" element={<Policys />} />
      </Route>
    </Routes>
  );
};

export default App;
