import React from "react";
import logo from "../images/logo.png";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";
import twitter from "../images/twitter.png";
import send from "../images/Submit.png";

export default function Footer() {
  return (
    <>
      <footer className="bg-[#EFEFFF] lg:px-4 xl:px-8 px-40 lg:py-4 xl:py-8 py-16">
        <div className="bg-[#fff] py-4 lg:px-4 px-20 rounded-2xl">
          <div className=" flex sm:flex-col justify-between  gap-10 lg:gap-7 xl:gap-20  mt-12 md:mt-0">
            <div className="mb-6 md:mb-0 xl:col-span-3 col-span-3">
              <img
                src={logo}
                alt="logo"
                className="w-[67px] md:w-[58px] md:h-[50px] h-[64.42px]"
              />

              {/* <div className="text-[15px] text-[#292D32] font-readex_light mt-7">
                Allignx Lorem ipsum Dolor sit amet maurisna husto mahna from non
                ro diam and discuss plates vivera magna loren ipsum.
              </div> */}

              {/* <div className="flex items-center gap-7  mt-5">
                <img src={facebook} alt="facebook" />
                <img src={twitter} alt="twitter" />
                <img src={instagram} alt="instagram" />
              </div> */}
            </div>

            <div className="grid grid-cols-1 gap-5">
              {/* <div>
                <div className="text-[17px] font-readex_regular text-[#3E46FE]">
                  Let's talk
                </div>

                <ul className="text-white font-custom mt-4">
                  <li className="mb-3 text-[15px] text-[#292D32]">
                    <a
                      href="mailto:support@allignx.com"
                      className="hover:underline break-words"
                    >
                      support@allignx.com
                    </a>
                  </li>
                </ul>
              </div> */}
              <a  href="/terms-policies" className="text-[17px] font-readex_regular text-[#3E46FE]">
                Terms & Policies
                <ul className="text-white font-custom mt-4">
                  {/* <li className="mb-3 text-[15px] text-[#292D32]">
                    <a href="/policies">Policies</a>
                  </li> */}
                  {/* <li className="mb-3 text-[15px] text-[#292D32]">
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>

                  <li className="mb-3 text-[15px] text-[#292D32]">
                    <a href="/terms-and-conditions">Terms and conditions</a>
                  </li> */}

                  {/* <li className="mb-3 text-[15px] text-[#292D32]">
                    <a href>Benefits</a>
                  </li>

                  <li className="mb-3 text-[15px] text-[#292D32]">
                    <a href>Team</a>
                  </li>

                  <li className="mb-3 text-[15px] text-[#292D32]">
                    <a href>Careers</a>
                  </li> */}
                </ul>
              </a>
            </div>

            {/* <div>
              <div className="text-[20px] text-[#1E2833] font-readex_regular">
                Subscribe to our <br /> newsletter
              </div>

              <div className="relative">
                <input
                  type="text"
                  placeholder="Email address"
                  className="border-b border-[#000000] border-opacity-10 py-3 mt-4 text-[15px] w-full text-[#1E2833] font-readex_regular outline-none"
                />
                <img
                  src={send}
                  alt="send"
                  className="w-[50px] absolute top-[13px] right-0 cursor-pointer"
                />
              </div>
            </div> */}
          </div>

          <hr className="my-6 border-[#000000] sm:border-none opacity-10" />

          <div className="flex flex-wrap items-center sm:justify-center justify-between mb-6">
            <div className="text-[15px] text-[#292D32] font-readex_light md:text-center text-start">
              Copyright © 2024. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
