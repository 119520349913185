import React, { useEffect, useState } from 'react'
import mammoth from 'mammoth';


export default function AcceptableUsePolicy() {
    const [htmlContent, setHtmlContent] = useState('');
    useEffect(() => {
        const fetchDocx = async () => {
            const response = await fetch('/assets/docs/Allignx_Acceptable use policy(1)_25-11-2024_Ver3.0.docx');
            const arrayBuffer = await response.arrayBuffer();

            mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
                .then(result => {
                    setHtmlContent(result.value); // The generated HTML
                });
        };
        
        fetchDocx();
    }, []);
    
    return (
        <div className="">
             <h1 className="font-readex_medium text-center text-4xl md:text-2xl capitalize mb-6">
             Acceptable Use <span className="text-[#3E46FE]">Policy</span>
            </h1>

            <div className="p-4">
                <div className='docdiv' dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>
        </div>
    );
}
